<script setup lang="ts">
import type { Provider } from '@supabase/supabase-js'

const { auth } = useSupabaseClient()
const loading = ref(false)

const handleLogin = async (provider: Provider) => {
  try {
    loading.value = true
    const { error } = await auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: `${useRuntimeConfig().public.appUrl}/login/confirm`,
      },
    })
    if (error) throw error
  } catch (error) {
    console.error(error)
    loading.value = false
  }
}
</script>
<template lang="pug">
div(class="grid gap-18 my-36")
  button(
    class="btn btn-auth-provider border border-gray-300 bg-gray-100 text-gray-600 hover:bg-gray-100 hover:text-gray-800 hover:border-gray-400"
    @click="handleLogin('google')"
  )
    Icon(
      mb-2
      name="svg-spinners:180-ring-with-bg"
      text-md
      v-if="loading"
    )
    Icon(
      name="logos-google-icon"
      class="text-md mb-2"
      v-if="!loading"
    )
    span Continue with Google

  //- button(
  //-   class="btn btn-auth-provider border border-gray-300 text-gray-200 border-gray-800 bg-gray-800 hover:bg-[#000] hover:text-gray-100"
  //-   @click="handleLogin('apple')"
  //- )
  //-   Icon(name="simple-icons:apple" class="text-md mb-2")
  //-   span Continue with Apple

  //- button(
  //-   class="btn btn-auth-provider border border-gray-300 text-gray-100 border bg-[#0a66c2] hover:bg-[#004182]"
  //-   @click="handleLogin('linkedin')"
  //- )
  //-   Icon(name="simple-icons:linkedin" class="text-md mb-2")
  //-   span Continue with LinkedIn
</template>
